import React from "react"
import { Link } from "gatsby"

import ReactTooltip from 'react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/outline'

import PlatformCard from "./platformcard"

const PromotedPlatforms = ({
  platforms, 
}) => {
  
  return (
    <div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <ReactTooltip id={`top-picks-tooltip`} place="right" effect="solid" backgroundColor="black" textColor="white"
            className="w-60"
          />
          <div className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
              </svg>
              <span>Top platform picks from the Hive Index</span>
            </div>
            <div data-for={`top-picks-tooltip`} data-tip={`These are our favorite community platforms right now. Some platforms listed in this section could be advertising partners.`}>
              <InformationCircleIcon className="ml-2 h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
            </div>
          </div>
          
        </div>
      </div>

      <div className="mt-1">
        <ul className={`mt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8`}>
          {platforms.map(p=>(
            <PlatformCard key={p.slug} platform={p} />
          ))}
        </ul>
        {/*<CommunitiesTable communities={promotedCommunities} showPlatform={true} linkModal={true} />*/}

        {platforms.length === 0 ? (
          <div className="border-dashed border-2 border-gray-100 hover:border-gray-200 rounded-md p-4 py-8 m-2 text-center">
            <div className="text-sm font-medium text-gray-400">Nothing here at this time</div>
            <div className="text-xs text-gray-400 mt-2">Would you like to <Link to="/partners/" className="underline cursor-pointer">promote your community</Link>?</div>
          </div>
        ) : ''}
      </div>
    </div>
  )
}


export default PromotedPlatforms


import React from "react"
import { Link } from "gatsby"

import Image from "../common/image"

const PlatformCard = ({ platform, highlightPromoted, hideCount, showDescription }) => {

  const link = `/platforms/${platform.slug}/`;

  return (
    <li key={link} className="relative">
      <Link to={link}>
        {highlightPromoted && platform.promoted ? (
          <div className="p-2 bg-rose-500 text-white absolute top-0 z-10 right-0 rounded-bl-md text-xs flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            <div>Top Pick</div>
          </div>
        ) : ''}
        
        <div className={`aspect-h-3 sm:aspect-h-7 group block w-full aspect-w-10 rounded-lg bg-white focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-rose-500 overflow-hidden ${highlightPromoted && platform.promoted ? 'ring-2 ring-rose-400' : ''}`}>
          {platform.logoBig ? (
            <img src={platform.logoBig} alt={`${platform.name} communities`}
              className={`p-6 object-contain filter grayscale-50 group-hover:grayscale-25`} />
          ) : (
            <Image className="p-6 object-contain filter opacity-50 grayscale-50 group-hover:grayscale-25" fileName="icon.png" alt="Community Placeholder"/>   
          )}
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">View details for {platform.name}</span>
          </button>
        </div>
        <p className={`text-lg mt-2 block font-medium text-rose-500 truncate pointer-events-none`}>
          {platform.name}
        </p>
        {!hideCount && platform.count ? (
          <p className="block text-xs text-gray-500 pointer-events-none">
            {platform.count} {platform.count === 1 ? platform.groupName : platform.groupNamePlural}
          </p>
        ) : !hideCount ? (
          <p className="block text-xs text-gray-500 pointer-events-none">
            {platform.groupNamePlural}
          </p>
        ) : ''}

        {showDescription ? (
          <p className="block text-sm text-gray-500 whitespace-pre-wrap">
            {platform.descriptionForCommunities}
          </p>
        ) : ''}
      </Link>
    </li>
  )

  // return (
  //   <Link to={`/platforms/${props.platform.slug}/`}
  //       style={{
  //           boxShadow:'0px 1px 3px rgba(0,0,0,0.25)',
  //           padding:'10px',
  //           borderRadius: '3px',
  //           margin: '10px',
  //           width: 'calc(33.33% - 20px)',
  //           minWidth: '200px',
  //           height: '200px',
  //           display: 'flex',
  //           flexDirection: 'column',
  //           justifyContent: 'center',
  //           textAlign: 'center',
  //           textDecoration: 'none',
  //           flexGrow: '1',
  //           maxWidth: '332px'
  //       }}
  //   >
  //       {props.platform.logoBig ? (
  //           <img src={props.platform.logoBig} height={50} style={{margin: '0 auto 10px', objectFit: 'contain'}} alt={`${props.platform.name} Logo`}/>
  //       ) : (
  //           <h3 style={{margin: '0'}}>{props.platform.name}</h3>
  //       )}
  //       <p style={{marginBottom: '0', marginTop: '5px'}}>
  //           {props.platform.count} {props.platform.count === 1 ? 'Community' : 'Communities'}
  //       </p>
  //   </Link>
  // )
}

export default PlatformCard

import React from "react"
import { graphql, Link } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import PlatformCard from "../components/platform/platformcard"
import PromotedPlatforms from "../components/platform/promotedPlatforms"

const PlatformsPage = ({data}) => {

  const platforms = data.platforms.nodes;
  const promotedPlatforms = platforms.filter(c => c.promoted);

  return (
    <Layout pageTitle="All Online Community Platforms" enableShare={true}>
      <SEO title="Platforms | Online Community Platforms" description="Discover online communities across popular platforms like Reddit, Slack, and LinkedIn. The list of communities and platorms is always expanding."
        generateImage={true} path={'/platforms/'}
      />

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Community Platforms on the Hive Index</h2>
        </div>
      </div>

      <div className="mt-2 text-gray-500 mb-6">
        <p>Online communities come in all shapes and sizes, and the platform on which the community is built plays a big role in the communication style and dynamics of interactions between members.
        Below are all of the community platforms that host the communities in the directory.</p><br/>
        {/*<p>When picking a community to join, or starting a community yourself, consider the features that the community platform enables.</p>*/}
      </div>
      
      {promotedPlatforms.length > 0 ? (
        <div className="mb-6">
          <PromotedPlatforms platforms={promotedPlatforms} />
        </div>
      ) : ''}
        
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">All Community Platforms</h2>
          <Link to="/suggest/">
            <button
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
            >
              <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>Suggest</span>
            </button>
          </Link>
        </div>
      </div>

      <ul className={`mt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8`}>
        {platforms.map(p=>(
          <PlatformCard key={p.slug} platform={p} highlightPromoted={true} />
        ))}
      </ul>

    </Layout>
  )
}

export const query = graphql`
query {
  platforms: allGoogleSpreadsheetSourcePlatforms(sort: {fields: count, order: DESC}) {
    totalCount
    nodes {
      slug
      name
      count
      logoBig
      groupName
      groupNamePlural
      promoted
    }
  }
}
`

export default PlatformsPage
